body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.maker {
  transform: translate(-50%,-100%);
}
.marker.red  path{
  fill: #f05305;
 
}

.marker.yellow  path{
  fill: #F3B73E;
}
.popup{
  max-width: 40vw;
  padding: 1rem;
}
.popup img{
  width: auto;
  height: 200px;
  text-align: center;
  margin: 0 auto;
  display: block;

}
.entry-form label,
 .entry-form input,
 .entry-form textarea,
 .entry-form button{
   font-size: 1rem;
margin: 0.5rem 0;
display: block;
width: 100%;
}
.error{
color: #f05305;
}